<div class="header-container">
  <h6 class="block-title">Basic Information</h6>  <div class="buttons-container">
    <button class="secondary-button-icons" (click)="editAvailability()">
      <span class="material-icons">edit</span>
    </button>
  </div>
</div>

<div class="photo-and-name-container">
  <img src="../../../../../assets/images/rick-astley.jpg" alt="" class="avatar">
  <h5 class="name">{{ participant.firstName }} {{ participant.lastName }}</h5>
  <span class="body-regular-text status" *ngIf="!isStudent && !isVolunteer">{{ participant.userRole | userRole }}</span>
  <span class="body-regular-text status" *ngIf="isStudent">Student <span *ngIf="hasAdditionalProfile">(has volunteer profile)</span></span>
  <span class="body-regular-text status" *ngIf="isVolunteer">Volunteer <span *ngIf="hasAdditionalProfile">(has student profile)</span></span>
</div>

<div class="participant-info">
  <div class="info-block">
    <span class="material-icons">mail</span>
    <span class="body-regular-text">{{ participant.email }}</span>
  </div>
  <div class="info-block">
    <span class="material-icons">person</span>
    <span class="body-regular-text">{{ participant.gender | gender | nullReplace }}</span>
  </div>
  <div class="info-block">
    <span class="material-icons">cake</span>
    <span class="body-regular-text">{{ participant.dateOfBirth | age | nullReplace }}</span>
  </div>
  <div class="info-block">
    <span class="material-icons">location_on</span>
    <span
      class="body-regular-text"
    >{{ participant.location }}</span>
  </div>

  <div class="info-block" *ngIf="participant.zipCode">
    <span class="material-icons">location_on</span>
    <span class="body-regular-text">{{ participant.zipCode }}</span>
  </div>

  <div class="info-block">
    <span class="material-icons">person_pin_circle</span>
    <span
      class="body-regular-text"
    >{{ participant.currentLocation }}</span>
  </div>


  <div class="inputs-container">
    <section class="example-section">
      <mat-checkbox disabled [class.mat-checkbox-disabled]="false" class="example-margin"
                    [checked]="participant.parentalPermission">
        Parental permission {{participant.parentalPermission ? 'signed' : 'not signed'}}
      </mat-checkbox>
    </section>
  </div>
</div>

<div *ngIf="isStudent && participant.miniGroup.studentMiniGroup">{{
  studentMiniGroups[participant.miniGroup.studentMiniGroup] }}</div>

<div *ngIf="isVolunteer && participant.miniGroup.volunteerIndividualSession">{{
  volunteerIndividualSessions[participant.miniGroup.volunteerIndividualSession] }}</div>

<div *ngIf="isVolunteer && participant.miniGroup.volunteerMiniGroupSession">{{
  volunteerMiniGroupSessions[participant.miniGroup.volunteerMiniGroupSession] }}</div>

<div class="section">
  <span class="caption-text">Programs</span>
  <div class="subprogram" *ngFor="let subprogram of participant.subprograms">
    <div class="color-circle" [style.background-color]="subprogram.color"></div>
    <span class="body-regular-text">{{ subprogram.name }}</span>
  </div>
</div>
<div class="section">
  <span class="caption-text">Source Name:</span>
  <span class="body-regular-text">{{ participant.sourceName | nullReplace }}</span>
</div>
<div class="section">
  <span *ngIf="participant.englishLevel" class="caption-text">English Level (estimated):</span>
  <span class="body-regular-text">{{ participant.englishLevel | english }}</span>
</div>
<div class="section" *ngIf="participant.englishTeachingExperience">
  <span class="caption-text">Prior experience:</span>
  <span class="body-regular-text">{{ participant.englishTeachingExperience }}</span>
</div>
<div class="section">
  <span class="caption-text">Motivation:</span>
  <span class="body-regular-text">{{ participant.reasonToJoin | nullReplace }}</span>
</div>
<div class="section">
  <span class="caption-text">Favorite Areas of Study:</span>
  <span class="body-regular-text">
    <span *ngFor="let a of participant.academicInterests">{{ a.value }}; </span>
    <span *ngIf="participant.academicInterests.length == 0">-</span>
  </span>
</div>
<div class="section">
  <span class="caption-text">Outside  Interests:</span>
  <span class="body-regular-text">
    <span *ngFor="let a of participant.hobbies">{{ a.value }}; </span>
    <span *ngIf="participant.hobbies.length == 0">-</span>
  </span>
</div>
<div class="section">
  <span class="caption-text">Student`s entry test score:</span>
  <span class="body-regular-text">{{ participant.entryTestScore | nullReplace }}</span>
</div>
<div class="section">
  <span class="caption-text">Time zone:</span>
  <span class="body-regular-text">{{ participant.timeZone | timeZoneInfo | nullReplace }}</span>
</div>
<div class="section">
  <span class="caption-text">Availability for Sessions</span>
  <div *ngFor="let time of times; index as i" class="time-container">
    <span class="overline-text">{{ time }}</span>
    <div class="days-container">
      <div
        *ngFor="let day of days; index as j"
        [ngClass]="{'day-disabled':availabilities.includes(i * 7 + j)}"
        class="day">
        <span class="caption-text">{{ day.short }}</span>
      </div>
    </div>
  </div>
</div>
<div class="section">
  <span class="caption-text">Comment:</span>
  <span class="body-regular-text">{{ participant.comment | nullReplace }}</span>
</div>
