export enum ParticipantStatus {
  INACTIVE = 100,
  REJECTED_AGE_LOW,
  REJECTED_AGE_HIGH,
  FAILED_TEST,
  DID_NOT_SCHEDULE_INTERVIEW,
  FAILED_INTERVIEW_LIGHT,
  FAILED_INTERVIEW_STRICT,
  DID_NOT_SIGN_AGREEMENT,
  DROPOUT_PENDING,
  DROPPED_OUT,
  KICKED_OUT,
  REJECTED_NOT_FROM_UKRAINE,
  INACTIVE_DIDNT_COMPLETE_PROFILE_OR_TEST,
  INACTIVE_DIDNT_COMPLETE_TRAINING,
  INACTIVE_ALUMNUS,
  INACTIVE_REJECTED_COURT_MANDATED,
  INACTIVE_REJECTED_OPINION_ON_WAR,
  REJECTED_COMMITMENT,
  INACTIVE_DIDNT_COMPLETE_INITIAL_ASSESSMENT,
  INCATIVE_WAITLIST,
  INACTIVE_PAYMENT_NOT_RECEIVED,
  INACTIVE_FROZEN,
  INACTIVE_REJECTED_VOLUNTEER_DIVERSITY,
  INACTIVE_REJECTED_VOLUNTEER_AGE_GROUP,
  INACTIVE_REJECTED_ONBOARDING_DURATION,
  ON_BOARDING = 200,
  PROFILE_IN_PROGRESS,
  PROFILE_COMPLETED,
  TEST_IN_PROGRESS,
  TEST_COMPLETED,
  INTERVIEW_SCHEDULED,
  INTERVIEW_CANCELLED_BY_PARTICIPANT,
  INTERVIEW_CANCELLED_BY_INTERVIEWER,
  INTERVIEW_NO_SHOW_PARTICIPANT,
  INTERVIEW_NO_SHOW_INTERVIEWER,
  INTERVIEW_IN_PROGRESS,
  INTERVIEW_COMPLETED,
  AGREEMENT_PENDING,
  TRAINING_PENDING,
  TRAINING_SCHEDULED,
  INTERVIEW_TECH_ISSUE,
  TRAINING_COMPLETED,
  ASSESSMENT_PENDING,
  PAYMENT_PENDING,
  SCHOLARSHIP_PENDING,
  PAYMENT_PENDING_SCHOLARSHIP,
  ACTIVE = 300,
  MATCHED,
  WAITING_FOR_MATCH,
  ON_THE_WAITLIST,
  REQUESTED_REMATCH,
  ACTIVE_FROZEN_PENDING
}
