import { PaymentRequirement } from '../../participants/manager/model/payment.types';
import { TimeZoneDto } from '../../timezone/model/time-zone-dto';

export interface ParticipantProfile {
  id: number;
  date: number;
  firstName: string;
  lastName: string;
  userRole: number;
  userRoles: number[];
  email: string;
  dateOfBirth: number;
  gender: number;
  country: string;
  state: string;
  city: string;
  location: string;
  englishLevel: number;
  englishTeachingExperience: string;
  reasonToJoin: string;
  academicInterests: QuestionAnswer[];
  hobbies: QuestionAnswer[];
  availability: QuestionAnswer[];
  entryTestScore: number;
  comment: string;
  currentLocation: string;
  currentCity: string;
  currentState?: string;
  currentCountry: string;
  parentalPermission: boolean;
  paymentRequirement?: PaymentRequirement;
  readyToBeVolunteer: boolean;
  paid?: boolean;
  timeZone?: TimeZoneDto;
  subprograms: {
    name: string;
    color: string;
  }[];
  miniGroup: MiniGroupPreference;
  zipCode?: string;
  sourceName: string;
}

export interface QuestionAnswer {
  id: number;
  value: string;
  intValue?: number;
}

export interface MiniGroupPreference {
  studentMiniGroup?: StudentMiniGroupPreference;
  volunteerIndividualSession?: number;
  volunteerMiniGroupSession?: number;
}

export enum StudentMiniGroupPreference {
  ONE_ON_ONE = 'ONE_ON_ONE',
  MINI_GROUP = 'MINI_GROUP',
  BOTH = 'BOTH',
}

export type VolunteerSession = {
  id: number;
  value: string;
};
